
import {defineComponent, ref, watch} from 'vue'
import InsuredIService from "@/core/services/InsuredIService";
import {Field} from "vee-validate";
import BaseForm from "@/components/base/form/BaseForm.vue";
import CustomerService from "@/core/services/CustomerService";
import {LoadPanel} from "@/core/composite/composite";

export default defineComponent({
  name: "ConfirmCustomer",

  components: {BaseForm, Field},
  props: {
    customer: {type: Object, required: true}
  },
  emits: ['saved'],
  setup(props) {
    const model = ref({insuredId: ''})
    const filter = ref({
      firstName: props.customer.firstName ? props.customer.firstName : '',
      lastName: props.customer.lastName ? props.customer.lastName : '',
      certNum: props.customer.certNum ? props.customer.certNum : '',
      policyNum: props.customer.policyNum ? props.customer.policyNum : ''
    })
    const insureds = ref([]);
    const loading = ref(false);

    const search = (filter) => {
      loading.value = true;
      InsuredIService.searchInsured(filter).then(res => {
        insureds.value = res;
      }).finally(() => {
        model.value.insuredId = '';
        loading.value = false;
      })
    }
    watch(() => props.customer, cb => {
      filter.value = {
        firstName: cb.firstName ? cb.firstName : '',
        lastName: cb.lastName ? cb.lastName : '',
        certNum: cb.certNum ? cb.certNum : '',
        policyNum: cb.policyNum ? cb.policyNum : ''
      }
      search(filter.value)
    })
    search(filter.value);
    return {
      filter,
      loading,
      model,

      ...LoadPanel(),
      insureds,
      search,
    }
  },
  methods: {
    onSearch() {
      this.search(this.filter);
    },

    submit() {
      this.loading = true;
      CustomerService.confirm(this.customer.id, this.model).then(res => {
        this.$emit('saved', res);
      }).finally(() => {
        this.loading = false
      })
    }
  }
})
