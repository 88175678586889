
import {computed, defineComponent} from 'vue'
import store from "@/store";
import {onUpdated} from "@vue/runtime-core";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import QuickAction from "@/components/base/action/QuickAction.vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import EntityViewAvatar from "@/components/base/common/EntityViewAvatar.vue";
import {LoadEntity, LoadPanel} from "@/core/composite/composite";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import BaseModal from "@/components/base/modal/BaseModal.vue";
import ConfirmCustomer from "@/views/customer/ConfirmCustomer.vue";
import CustomerStatus from "@/views/customer/CustomerStatus.vue";
import TabNavigate from "@/components/base/common/TabNavigate.vue";

export default defineComponent({
  name: "Customer",
  components: {
    TabNavigate,
    CustomerStatus,  ConfirmCustomer, BaseModal, EntityViewAvatar, DateTimeFormat, QuickAction
  },
  setup() {
    const entity = computed(() => store.state.CustomerModule.entity);
    onUpdated(() => {
      setCurrentPageBreadcrumbsWithParams('Customer Details', [
        {link: true, router: '/customer/new', text: 'New Customers'},
        {link: false, router: '', text: 'View Customer'}
      ])
    })
    return {
      entity,
      ...LoadPanel(),
      ...LoadEntity(Actions.LOAD_CUSTOMER)
    }
  },
  methods: {
    onConfirm() {
      const modal = this.$refs.confirmCustomerRef as typeof BaseModal
      modal.showBaseModal();
    },
    onConfirmed(data) {
      store.commit(Mutations.SET_CUSTOMER, data)
      const modal = this.$refs.confirmCustomerRef as typeof BaseModal
      modal.hideBaseModal();
    },
    buildNav(data) {
      return [
        {id: 'overview', router: `/customer-info/` + data.id + "/overview", name: 'Overview'},
        {id: 'claims', router: `/customer-info/` + data.id + '/claims', name: 'Claims'},
        {id: 'inbox', router: `/customer-info/` + data.id + '/inbox', name: 'Inbox'},
      ]
    }
  }
})
