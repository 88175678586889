
import {defineComponent, ref, watch} from 'vue'

export default defineComponent({
  name: "CustomerStatus",
  props :{
    status : {}
  },
  setup(props) {
    const data = ref({name: '', clazz: ''});

    const build = (status) => {
      switch (status) {
        case 'NEW':
          data.value = {name: 'New', clazz: 'badge-secondary'}
          break
        case 'CONFIRMED':
          data.value = {name: 'Confirmed', clazz: 'badge-info'}
          break
      }
    }
    build(props.status)

    watch(() => props.status, cb => {
      build(cb);
    })

    return {
      data
    }
  }
})

